import jQuery from 'jquery'
import popper from 'popper.js'
import bootstrap from 'bootstrap'
import 'jquery-mask-plugin'
import AOS from 'aos'

AOS.init();

(function( $ ){
  $.fn.toggleCustomCheckbox = function() {
    if(this.val() === '') {
      this.addClass('on').removeClass('off')
    } else {
      this.addClass('off').removeClass('on')
    }
    return this
  }
  $.fn.validate = function () {
    let isValid = true
    let checkbox = this.find('.invisible')
    if(!checkbox.is(':checked')) {
      isValid = false
      this.find('.invalid-feedback-cbx').fadeIn()
      setTimeout(() => {
        this.find('.invalid-feedback-cbx').fadeOut()
      }, 4000)
    } else {
      this.find('.invalid-feedback-cbx').fadeOut()
    }

    let name = this.find('input[name=name]')
    if(name.attr('required')) {
      if (name.val() === '') {
        isValid = false
        this.find('.invalid-feedback-name').fadeIn()
        setTimeout(() => {
          this.find('.invalid-feedback-name').fadeOut()
        }, 4000)
      } else {
        this.find('.invalid-feedback-name').fadeOut()
      }
    }

    let phone = this.find('input[name=phone]')
    if(phone.attr('required')) {
      if (phone.val() === '') {
        isValid = false
        this.find('.invalid-feedback-phone').fadeIn()
        setTimeout(() => {
          this.find('.invalid-feedback-phone').fadeOut()
        }, 4000)
      } else {
        this.find('.invalid-feedback-phone').fadeOut()
      }
      if(!(/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/g).test(phone.val())) {
        isValid = false
        this.find('.invalid-feedback-phone-format').fadeIn()
        setTimeout(() => {
          this.find('.invalid-feedback-phone-format').fadeOut()
        }, 4000)
      }
    }

    return isValid
  }
})( jQuery )

jQuery(function($) {

  $(window).scroll(function(){
    let $sections = $('section')
    $sections.each(function(i,el){
      let top  = $(el).offset().top-100
      let bottom = top +$(el).height()
      let scroll = $(window).scrollTop()
      let id = $(el).attr('id')
      if( scroll > top && scroll < bottom){
        $('a.nav-link.active').removeClass('active')
        $('a[href="#'+id+'"]').addClass('active')
      }
    })
  });

  $('nav').on('click','a.nav-link', function (event) {
    // исключаем стандартную реакцию браузера
    event.preventDefault();

    // получем идентификатор блока из атрибута href
    let id  = $(this).attr('href'),

      // находим высоту, на которой расположен блок
      top = $(id).offset().top
    // анимируем переход к блоку, время: 800 мс
    $('body,html').animate({scrollTop: top}, 500)
  })

  $(window).on('scroll', function() {
    if($(window).scrollTop()) {
      $('nav').addClass('sticky')
    } else {
      $('nav').removeClass('sticky')
    }
  })
  if($(window).scrollTop()) {
    $('nav').addClass('sticky')
  }

  $('input[name=phone]').mask('+7 (000) 000-00-00')

  $('#modal-callback').find('form').submit(function(e) {
    e.preventDefault()
    if(!$(this).validate()) return false

    $.ajax({
      url: 'mail.php',
      type: 'POST',
      data: $(this).serialize(),
      dataType: 'json'
    }).done(() => {
      let modal = $('#modal-callback')
      modal.find('form').fadeOut()
      modal.find('h3').fadeIn()
    }).fail(() => {
      alert( "Произошла ошибка! Попробуйте позвонить по указанному на сайте номеру телефона." )
    })
  })
  $('#question-form').submit(function (e) {
    e.preventDefault()
    if(!$(this).validate()) return false
    sendEmail($(this))
  })
  $('#services-form').submit(function (e) {
    e.preventDefault()
    if(!$(this).validate()) return false
    sendEmail($(this))
  })
  function sendEmail (form) {
    $.ajax({
      url: 'mail.php',
      type: 'POST',
      data: form.serialize(),
      dataType: 'json'
    }).done(() => {
      let modal = $('#modal-callback')
      modal.find('form').hide()
      modal.find('h3').show()
      modal.modal('show')
    }).fail(() => {
      alert( "Произошла ошибка! Попробуйте позвонить по указанному на сайте номеру телефона." )
    })
  }
})
